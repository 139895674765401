import React, { ReactNode } from 'react'
import * as SubNavStyles from './SubNav.module.scss'

interface SubnavProps {
  children: ReactNode
  alignCenter?: boolean
  pageWrapper?: boolean
}

const SubNav = ({ children, alignCenter, pageWrapper }: SubnavProps) => {
  return (
    <div
      className={`${SubNavStyles.Wrapper} ${
        alignCenter ? SubNavStyles.AlignCenter : SubNavStyles.SpaceBetween
      } ${pageWrapper ? SubNavStyles.PageWrapper : ''}`}
    >
      {children}
    </div>
  )
}

export default SubNav
