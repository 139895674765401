import React, { ReactNode } from 'react'
import CollapsibleNav from '../CollapsibleNav/CollapsibleNav'
import Hr from '../Hr/Hr'
import Subnav from '../SubNav/SubNav'
import * as LeaderStyles from './Leader.module.scss'
import { Link } from 'gatsby'
import { IMenu } from '../../graphql/entities/Menu'

interface LeaderProps {
  children: ReactNode
  subMenu?: IMenu[]
  collapsibleNav?: boolean
}

const Leader = ({ children, subMenu, collapsibleNav }: LeaderProps) => {
  const Menu = () => {
    if (!subMenu) return null

    return collapsibleNav ? (
      <CollapsibleNav menu={subMenu} />
    ) : (
      <div className={LeaderStyles.LayoutSpacer}>
        <Subnav>
          <ul>
            {subMenu?.map(item => (
              <li id={item?.id} key={item?.id}>
                <Link to={`/`}>{item?.label}</Link>
              </li>
            ))}
          </ul>
        </Subnav>
        <Hr />
      </div>
    )
  }

  return (
    <div className={LeaderStyles.Wrapper}>
      {children}
      <Menu />
    </div>
  )
}

export default Leader
