import React from 'react'
import { graphql } from 'gatsby'

import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import CTARow from '../components/CTA/CTARow'
import Blocks from '../components/Blocks/Blocks'
import Leader from '../components/Leader'
import TopBanner from '../components/Banners/TopBanner/TopBanner'
import * as styles from '../components/layoutComponents/littleBlackBook/LittleBlackBook.module.scss'
import * as LeaderStyles from '../components/Leader/Leader.module.scss'
import PageMeta from '../components/PageMeta/PageMeta'
import { type ILittleBlackBookData } from '../graphql/queries/LittleBlackBook'
import Leaderboard from '../components/Banners/Leaderboard/Leaderboard'
import MPUBanner from '../components/Banners/MPUBanner/MPUBanner'

export const query = graphql`
  query {
    wpgraphql {
      latestLBB: littleBlackBooks(
        first: 18
        where: { orderby: { field: DATE, order: DESC } }
      ) {
        nodes {
          id
          title
          uri
          featuredImage {
            node {
              mediaItemUrl
              altText
              spotlightDesktop: sourceUrl(size: HOMEPAGE_SPOTLIGHT_DESKTOP)
              spotlightMobile: sourceUrl(size: HOMEPAGE_SPOTLIGHT_MOBILE)
              homepagePreviewImage: sourceUrl(size: HOMEPAGE_PREVIEW)
            }
          }
          primaryCategory
          primaryCategoryURI
        }
      }
      trendingLBB: posts(
        where: {
          categoryName: "trending"
          orderby: { field: DATE, order: DESC }
        }
        first: 4
      ) {
        nodes {
          id
          title
          uri
          featuredImage {
            node {
              mediaItemUrl
              altText
              spotlightDesktop: sourceUrl(size: HOMEPAGE_SPOTLIGHT_DESKTOP)
              spotlightMobile: sourceUrl(size: HOMEPAGE_SPOTLIGHT_MOBILE)
              homepagePreviewImage: sourceUrl(size: HOMEPAGE_PREVIEW)
            }
          }
          primaryCategory
          primaryCategoryURI
        }
      }
      lbbMenu: menu(id: "LBB Menu", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      adStarCodes {
        id
        adslot
      }
      generalSettings {
        title
        url
        egSettingTwitter
      }
    }
  }
`

interface LBBFrontPageTemplateProps {
  data: ILittleBlackBookData
}

const LBBFrontPageTemplate = ({
  data: {
    wpgraphql: { lbbMenu, latestLBB, trendingLBB, adStarCodes, generalSettings }
  }
}: LBBFrontPageTemplateProps) => {
  const { title, url } = generalSettings

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: "Muddy Stilettos' Little Black Book of Best Businesses",
          description:
            "A tailored list of companies we think you should know about. Whether you're looking for pubs, hotels, bars or wedding venues, we have the best in the biz.",
          url: `${url}/little-black-book`
        }}
      />
      <Header
        siteId={generalSettings.siteId}
        siteName={generalSettings.title}
      />
      <TopBanner ads={adStarCodes} showMPUs pageType={`subcategory`} />
      <Leaderboard adSlot={'TopLB'} sticky />
      <Leader subMenu={lbbMenu?.menuItems.nodes} collapsibleNav={true}>
        <>
          {/* <StaticImage
            src={`../../static/images/littleblackbook_header.jpg`}
            alt="Little Black Book"
          /> */}
          <div className={LeaderStyles.InnerWrapper}>
            <h1>
              Lit<span className={styles.kerning}>t</span>le Black Book
            </h1>
            <p>
              The very best businesses and brands in{' '}
              {title?.replace('&amp;', '&')}, handpicked by Muddy Stilettos
            </p>
          </div>
        </>
      </Leader>
      {latestLBB && (
        <>
          <Blocks
            title={`Latest`}
            hasSpotlight
            posts={latestLBB.nodes.slice(0, 9)}
            leaderboard="MidLB1"
          />
          <MPUBanner
            adSlots={['Row1MPU1', 'Row1MPU2', 'Row1MPU3', 'Row1MPU4']}
          />
          <Blocks
            hasSpotlight
            leaderboard="MidLB2"
            alignRight
            posts={latestLBB.nodes.slice(-9)}
          />
        </>
      )}
      <MPUBanner adSlots={['Row2MPU1', 'Row2MPU2', 'Row2MPU3', 'Row2MPU4']} />
      {trendingLBB && <Blocks title={`Trending`} posts={trendingLBB.nodes} />}
      <CTARow>
        <h3>Get Muddy</h3>
        <p>
          Sign up for our inside line on the most brilliant things happening
          near you
        </p>
        <p>
          <a href="/sign-up">Newsletter sign up</a>
        </p>
      </CTARow>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default LBBFrontPageTemplate
