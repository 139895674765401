import React, { useState } from 'react'
import Hr from '../Hr/Hr'
import * as CollapsibleNavStyles from './CollapsibleNav.module.scss'
import { fixURL } from '../../lib/utils'
import { IMenu } from '../../graphql/entities/Menu'

interface CollapsibleNavProps {
  menu: IMenu[]
}

const CollapsibleNav = ({ menu }: CollapsibleNavProps) => {
  const [menuVisibility, setMenuVisibility] = useState<boolean>(false)

  return (
    <div className={CollapsibleNavStyles.Base}>
      <span
        className={CollapsibleNavStyles.Toggle}
        onClick={() => setMenuVisibility(!menuVisibility)}
      >
        {menuVisibility ? 'Hide' : 'Show'} categories
      </span>
      <Hr />
      <div
        className={`${CollapsibleNavStyles.Menu} ${
          menuVisibility
            ? CollapsibleNavStyles.isVisible
            : CollapsibleNavStyles.isHidden
        }`}
      >
        <ul>
          {menu.map(
            menuItem =>
              menuItem && (
                <li key={menuItem.id}>
                  <a href={fixURL(menuItem.path ?? '')}>{menuItem.label}</a>
                </li>
              )
          )}
        </ul>
      </div>
    </div>
  )
}

export default CollapsibleNav
